<template>
  <div class="storeRoomList-wrapper">
    <list
      ref="list"
      exportPermission="export"
      :searchUrl="searchUrl"
      :exportUrl="exportUrl"
      :searchParams.sync="searchParams"
      :headers="headers"
      :handleData="handleData"
    >
      <template #headerSlot>
        <v-button text="导入" permission="add" @click="goPageImport"></v-button>
        <v-button text="新增" permission="add" @click="create"></v-button>
      </template>
      <template #searchSlot>
        <v-datepicker label="创建时间" :startTime.sync="searchParams.startDate" :endTime.sync="searchParams.endDate" type="rangedatetimer" defaultTimeType="month"></v-datepicker>
        <v-input label="用户姓名" v-model="searchParams.userName"></v-input>
        <v-input label="手机号" v-model="searchParams.mobileNum"></v-input>
        <v-input label="用户房产信息" v-model="searchParams.userAddress"></v-input>
        <v-select label="库房状态 " v-model="searchParams.status" :options="statusOps"></v-select>
        <v-select2 label="库房地址" v-model="searchParams.storeroomHouseId" v-bind="storeroomParams"></v-select2>
        <v-select2 label="所属项目" v-model="searchParams.communityId" v-bind="communityParams"></v-select2>
        <v-input label="苑" v-model="searchParams.blockName"></v-input>
        <v-input label="幢" v-model="searchParams.buildingNumber"></v-input>
        <v-input label="单元" v-model="searchParams.unit"></v-input>
        <v-input label="室" v-model="searchParams.room"></v-input>
      </template>
      <template #operateSlot="scope">
        <v-button v-if="scope.row.status === '正常'" text="编辑" type="text" permission="update" @click="edit(scope.row)"></v-button>
      </template>
    </list>
  </div>
</template>

<script>
import { getListURL, exportListURL, getStoreroomListURL } from './api'
import { statusMap } from './map'
import { communityParams } from 'common/select2Params'

export default {
  name: 'storeRoomList',
  data () {
    let _this = this
    return {
      searchUrl: getListURL,
      exportUrl: exportListURL,
      communityParams,
      statusOps: [
        {
          text: '全部',
          value: undefined
        }, {
          text: '正常',
          value: 0
        }, {
          text: '关闭',
          value: 1
        }
      ],
      storeroomParams: {
        searchUrl: getStoreroomListURL,
        request: {
          text: 'roomNum',
          value: 'houseId'
        },
        response: {
          text: 'name',
          value: 'id'
        }
      },
      searchParams: {
        startDate: '', // 开始时间
        endDate: '', // 结束时间
        userName: '', // 用户姓名
        mobileNum: '', // 手机号
        userAddress: '', // 用户房产信息
        status: undefined,
        storeroomHouseId: '', // 库房地址
        communityId: '', // 所属项目
        blockName: '', // 苑
        buildingNumber: '', // 幢
        unit: '', // 单元
        room: '' // 室
      },
      headers: [
        {
          prop: 'storeRoomAddress',
          label: '库房地址'
        },
        {
          prop: 'communityName',
          label: '所属项目'
        },
        {
          prop: 'userName',
          label: '用户姓名',
          formatter: (val) => {
            let h = _this.$createElement
            return h('div', {
              domProps: {
                innerHTML: `${val.userName}</br>${val.mobileNum}`
              }
            })
          }
        },
        {
          prop: 'userAddress',
          label: '用户房产信息'
        },
        {
          prop: 'handleTime',
          label: '起止时间',
          formatter: (val) => {
            let h = _this.$createElement
            if (val.handleTime || val.expireTime) {
              return h('div', {
                domProps: {
                  innerHTML: `${val.handleTime ? '从' + val.handleTime + '</br>' : ''}${val.expireTime ? '到' + val.expireTime : ''}`
                }
              })
            } else {
              return '/'
            }
          }
        },
        {
          prop: 'status',
          label: '库房状态'
        },
        {
          prop: 'intime',
          label: '创建时间'
        }
      ]
    }
  },
  methods: {
    handleData (item) {
      item.statusText = statusMap[item.status]
    },
    create () {
      this.$router.push({
        name: 'storeRoomForm'
      })
    },
    edit (row) {
      this.$router.push({
        name: 'storeRoomForm',
        query: {
          id: row.id
        }
      })
    },
    goPageImport () {
      this.$router.push({
        name: 'storeRoomImport'
      })
    }
  }
}
</script>
